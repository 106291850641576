import styled from "styled-components";

import { Whatsapp } from "@styled-icons/boxicons-logos/Whatsapp";

export const PageWrapper = styled.section`
  background: #e5e5e5;
  padding-bottom: 159px;
  @media only screen and (max-width: 1250px) {
    padding-bottom: 0;
  }
  .container {
    .title {
      padding: 86px 0 57px;
      width: 210px;
      margin: 0 auto;
      @media only screen and (max-width: 1400px) {
        padding: 41px 0 17px;
        width: 120px;
      }
    }
    span {
      display: block;
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: rgba(47, 49, 55, 0.5);
      padding-bottom: 86px;
      @media only screen and (max-width: 1400px) {
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 75px;
      }
    }
    .contactContainer {
      display: grid;
      grid-gap: 100px 50px;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 175px;
      @media only screen and (max-width: 1400px) {
        grid-gap: 108px;
        grid-template-columns: 1fr;
        padding-bottom: 109px;
      }
      .contactItem {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
          font-weight: bold;
          font-size: 32px;
          line-height: 39px;
          color: #2f3137;
          padding-bottom: 38px;
          @media only screen and (max-width: 800px) {
            font-size: 24px;
            line-height: 29px;
          }
        }
        .iconText {
          display: flex;
          align-items: center;
          padding-bottom: 16px;
          svg {
            width: 20px;
            height: 20px;
            color: #2f3137;
            margin-right: 18px;
          }
          p {
            font-size: 20px;
            line-height: 24px;
            color: #8a8b8e;
            @media only screen and (max-width: 800px) {
              font-size: 16px;
              line-height: 19px;
            }
          }
        }
        strong {
          display: inline-block;
          padding: 41px 0 7px;
          font-size: 20px;
          line-height: 24px;
          color: #2f3137;
          @media only screen and (max-width: 800px) {
            font-size: 16px;
            line-height: 19px;
          }
        }
        .contactP {
          font-size: 20px;
          line-height: 24px;
          color: rgba(47, 49, 55, 0.5);
          padding-bottom: 57px;
          @media only screen and (max-width: 800px) {
            font-size: 16px;
            line-height: 19px;
          }
        }
        .contactLinks {
          display: flex;
          align-items: center;
          @media only screen and (max-width: 800px) {
            flex-direction: column;
          }
          .contactBtn {
            display: flex;
            align-items: center;
            padding: 16px 24px;
            border-radius: 8px;
            @media only screen and (max-width: 800px) {
              width: 100%;
              justify-content: center;
            }
            svg {
              width: 25px;
              height: 25px;
              margin-right: 11px;
            }
            p {
              font-size: 20px;
              line-height: 24px;
              @media only screen and (max-width: 800px) {
                font-size: 16px;
                line-height: 19px;
              }
            }
          }
          .btn1 {
            color: #ffffff;
            background: #69cf67;
            border: 2px solid #69cf67;
            margin-right:24px;
            @media only screen and (max-width: 800px) {
              margin-right:0;
            }
            &:hover {
              background: #51ba4f;
              border: 2px solid #51ba4f;
            }
          }
          .btn2 {
            color: rgba(47, 49, 55, 0.5);
            border: 2px solid rgba(41, 50, 60, 0.2);
            margin: 0 40px 0 0;
            @media only screen and (max-width: 800px) {
              margin: 16px 0 21px;
            }
            &:hover {
              background: rgba(41, 50, 60, 0.1);
            }
          }
          .emailLink {
            font-size: 20px;
            line-height: 24px;
            text-decoration-line: underline;

            color: #c4c4c4;
            &:hover {
              color: rgba(47, 49, 55, 0.5);
            }
            @media only screen and (max-width: 800px) {
              font-size: 16px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
  .blockForm {
    margin: 0 auto;
    h2 {
      width: 300px;
      @media only screen and (max-width: 1400px) {
        width: auto;
      }
    }
  }
`;

export const WhatsappWhite = styled(Whatsapp)`
  color: white;
`;
