import React from "react";
import FeatherIcon from "feather-icons-react";
import InputMask from "react-input-mask";

import Layout from "../../components/Layout";
import * as S from "../../styles/contact/styles";

const Contact = () => {
  return (
    <Layout>
      <S.PageWrapper>
        <div className="container">
          <div className="title t1">
            <h2>Contato</h2>
            <div className="border"></div>
          </div>
          <span>Pontos de coleta</span>
          <div className="contactContainer">

            <div className="contactItem">
              <h3>CEMICE</h3>
              <div className="iconText">
                <FeatherIcon icon="phone" />
                <p>3323-1910</p>
              </div>
              <div className="iconText">
                <FeatherIcon icon="map-pin" />
                <p>Rua Alfredo de Padua, 92 - Centro - Ceres</p>
              </div>
              <strong>Horário de atendimento:</strong>
              <p className="contactP">24 horas</p>
              <div className="contactLinks">
                <a href="" className="contactBtn btn1 hidden">
                  <S.WhatsappWhite />
                  <p>Whatsapp</p>
                </a>
                <a href="https://www.google.com/maps/place/Cemice/@-15.3062888,-49.5967405,17z/data=!4m13!1m7!3m6!1s0x0:0x0!2zMTXCsDE4JzIwLjIiUyA0OcKwMzUnNTAuMCJX!3b1!8m2!3d-15.3056116!4d-49.5972176!3m4!1s0x935d3a0e50d2ae21:0x84dcdc7215d3f0c0!8m2!3d-15.3061912!4d-49.5968248?hl=pt-BR" className="contactBtn btn2">
                  <FeatherIcon icon="map" />
                  <p>Localização</p>
                </a>
                <a href="" className="emailLink hidden">
                  Email
                </a>
              </div>
            </div>

            <div className="contactItem">
              <h3>C.D.C</h3>
              <div className="iconText">
                <FeatherIcon icon="phone" />
                <p>3307-1996</p>
              </div>
              <div className="iconText">
                <FeatherIcon icon="map-pin" />
                <p>Rua São Patrício, 274 - Centro Ceres - GO</p>
              </div>
              <strong>Horário de atendimento:</strong>
              <p className="contactP">Segunda à Sexta das 7h às 17h</p>
              <div className="contactLinks">
                <a href="" className="contactBtn btn1 hidden">
                  <S.WhatsappWhite />
                  <p>Whatsapp</p>
                </a>
                <a href="https://www.google.com/maps/place/15°18'39.0%22S+49°35'39.9%22W/@-15.3108454,-49.5966165,17z/data=!4m6!3m5!1s0x0:0x0!4b1!8m2!3d-15.3108454!4d-49.5944278?hl=pt-BR" className="contactBtn btn2">
                  <FeatherIcon icon="map" />
                  <p>Localização</p>
                </a>
                <a href="" className="emailLink hidden">
                  Email
                </a>
              </div>
            </div>

            <div className="contactItem">
              <h3>Bom Jesus</h3>
              <div className="iconText">
                <FeatherIcon icon="phone" />
                <p>3323-1920</p>
              </div>
              <div className="iconText">
                <FeatherIcon icon="map-pin" />
                <p>Av. Brasil, nº 80 - Centro, Ceres - GO</p>
              </div>
              <strong>Horário de atendimento:</strong>
              <p className="contactP">Segunda à Sexta das 7h às 15h</p>
              <div className="contactLinks">
                <a href="" className="contactBtn btn1 hidden">
                  <S.WhatsappWhite />
                  <p>Whatsapp</p>
                </a>
                <a href="https://www.google.com/maps/place/Hospital+Bom+Jesus+-+Cardiologia+Oftalmologia+Urologia+Dermatologia+Cirurgia+Geral+Endocrinologia/@-15.3108828,-49.5923023,15z/data=!4m5!3m4!1s0x0:0x95aca6f625d61771!8m2!3d-15.3108828!4d-49.5923023" className="contactBtn btn2">
                  <FeatherIcon icon="map" />
                  <p>Localização</p>
                </a>
                <a href="" className="emailLink hidden">
                  Email
                </a>
              </div>
            </div>

          </div>
        </div>
        <div className="blockForm">
          <div>
            <h2>Fale conosco</h2>
          </div>
          <form action="/success2"  method="POST" name="contact" data-netlify="true">
            <input type="hidden" name="form-name" value="contact"/>
            <input name="Nome" type="text" className="inputText" placeholder="Nome" />
            <InputMask
              className="inputText"
              name="Telefone"
              placeholder="Whatsapp"
              mask="(99) 99999-9999"
              required
              maskChar={null}
            />
            <input name="Email" type="text" className="inputText" placeholder="Email" />
            <textarea
              name="Mensagem"
              className="inputText"
              rows="5"
              cols="33"
              placeholder="Mensagem"
            />
            <button className="btn">Enviar</button>
          </form>
        </div>
      </S.PageWrapper>
    </Layout>
  );
};

export default Contact;